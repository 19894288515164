import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

export const todayDateOnlyDay = () => {
  var d = new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d.getFullYear() + "_" + (d.getMonth() + 1) + "_" + d.getDate();
};

export function range(start, end) {
  return Array.apply(0, Array(end - 1)).map((element, index) => index + start);
}

//For sorting the posts by date
export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    //If customdate is given sort by that
    //NOTE: This only sorts per batch, with customdate somethings might be in the wrong batch
    var aProperty = property;
    var bProperty = property;
    if (property.includes("creationDateAsNumber")) {
      if (a.data().useCostumDate) {
        aProperty = "customDateAsNumber";
      }
      if (b.data().useCostumDate) {
        bProperty = "customDateAsNumber";
      }
    }
    var result =
      a.data()[aProperty] < b.data()[bProperty]
        ? -1
        : a.data()[aProperty] > b.data()[bProperty]
        ? 1
        : 0;

    return result * sortOrder;
  };
}

export const SemiRandomColor = (idx) => {
  const items = ["indigo", "cyan", "teal", "lime", "blue", "orange", "red"];
  return items[idx % items.length];
};

export const SemiRandomTWColor = (idx) => {
  const items = [
    "from-lime-400",
    "from-orange-400",
    "from-sky-400",
    "from-yellow-400",
    "from-teal-400",
    "from-neutral-400",
    "from-cyan-400",
    "from-amber-400",
    "from-emerald-400",
    "from-red-400",
  ];
  return items[idx % items.length];
};

// Construct a firestore query from a map of required properties
export function ConstructBaseQuery(q, dbRef, query_items) {
  // Double loop through the keys and their lists (i.e. {authors: ['kees', 'jan'], tags:['cool']})
  for (const key of Object.keys(query_items)) {
    const items_for_key = query_items[key];
    if (items_for_key) {
      for (var i = 0; i < items_for_key.length; i++) {
        //  If no query is formed yet
        if (q === null)
          q = query(
            dbRef,
            where(`${key}.` + String(items_for_key[i]), "==", true)
          );
        // If the query is already formed
        else
          q = query(q, where(`${key}.` + String(items_for_key[i]), "==", true));
      }
    }
  }
  if (q === null) q = dbRef;
  return q;
}

// Post html to post text
export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}
