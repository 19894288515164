import logo from "./logo.svg";
import "./App.css";
import React, { Suspense, lazy, useState, useEffect } from "react";
import { IKContext } from "imagekitio-react";
import { BrowserRouter as Router, Route } from "react-router-dom";

//Import firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

//
import { Home } from "./pages/Home";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { Switch } from "react-router-dom";
import { NavBar } from "./NavBar";

// Pages
const Dashboard = lazy(() => import("./CMS/pages/Dashboard"));
const Editor = lazy(() => import("./CMS/pages/Editor"));
const Post = lazy(() => import("./CMS/pages/Post"));
const More = lazy(() => import("./CMS/pages/More"));

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFHXNy5p2_sYFtQ-bQ9MgS-fkZnJdjOjE",
  authDomain: "pitchai-92bdd.firebaseapp.com",
  projectId: "pitchai-92bdd",
  storageBucket: "pitchai-92bdd.appspot.com",
  messagingSenderId: "260109571892",
  appId: "1:260109571892:web:ca5df3412927fe55e7b761",
  measurementId: "G-5X3WSW2P9M",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth();
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

function App() {
  // Keep track of the current logged in user
  const [user, setUser] = useState();
  const [metadata, setMetaData] = useState();
  const [loadedMetadata, setLoadedMetadata] = useState(false);
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
    }
  });

  useEffect(() => {
    if (!loadedMetadata) {
      setLoadedMetadata(true);
      const onSnap = onSnapshot(doc(db, "metadata", "metadata"), (docu) => {
        console.log("Current data: ", docu.data());
        setMetaData(docu.data());
      });
    }
  }, []);

  return (
    <Router>
      <MantineProvider>
        <NotificationsProvider>
          <IKContext urlEndpoint="https://ik.imagekit.io/cis">
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route
                  path="/dashboard/:succes?"
                  render={(props) => (
                    <Dashboard
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      analytics={analytics}
                      firebase={app}
                      auth={auth}
                      signInSuccessUrl="http://localhost:3000/dashboard"
                      functions={functions}
                      buttonStyles="bg-gradient-to-br from-green-500 to-cyan-600 hover:bg-gradient-to-bl"
                      navbar={
                        <NavBar
                          addCss={"bg-slate-50"}
                          noCall
                          standalone
                          analytics={analytics}
                        ></NavBar>
                      }
                    />
                  )}
                />
                <Route
                  path="/editpost/:name"
                  render={(props) => (
                    <Editor
                      {...props}
                      metadata={metadata}
                      algoliaClient="FMKU2ZJ5C2"
                      algoliaSecret="88cc157c1f97e7df58305c0f1febca91"
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      isValidMail={true}
                      analytics={analytics}
                      allowStyles={true}
                      functions={functions}
                      buttonStyles="bg-gradient-to-br from-green-500 to-cyan-600 hover:bg-gradient-to-bl"
                    />
                  )}
                />
                <Route
                  path="/more/:categories?/:tags?/:authors?/:publicists?"
                  render={(props) => (
                    <More
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      functions={functions}
                    />
                  )}
                />
                <Route
                  path="/posts/:name"
                  render={(props) => (
                    <>
                      {" "}
                      <NavBar></NavBar>
                      <Post
                        {...props}
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        analytics={analytics}
                        buttonStyles="bg-gradient-to-br from-green-500 to-cyan-600 hover:bg-gradient-to-bl"
                        auth={auth}
                      />
                    </>
                  )}
                />
                <Route
                  path="/home/:section?"
                  render={(props) => (
                    <Home
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      analytics={analytics}
                      auth={auth}
                    />
                  )}
                />
                <Route
                  path="/:section?"
                  render={(props) => (
                    <Home
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      analytics={analytics}
                      auth={auth}
                    />
                  )}
                />
                <Route
                  path="/?"
                  render={(props) => (
                    <Home
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      analytics={analytics}
                      auth={auth}
                    />
                  )}
                />
              </Switch>
            </Suspense>
          </IKContext>
        </NotificationsProvider>
      </MantineProvider>
    </Router>
  );
}

export default App;
