import React from "react";

export const BulletPoint = (props) => {
  return (
    <div className="flex gap-6  justify-between my-8 items-center">
      <div className="">
        {" "}
        <h4 className="text-gray-700 text-xl">{props.title}</h4>
        <p className="text-gray-500 text-sm">{props.text}</p>
      </div>
      <img className="w-32 h-32" src={props.img}></img>
    </div>
  );
};
