import React, { useEffect, useState } from "react";
import report from "../img/report.svg";
import ad from "../img/ad.svg";
import creative from "../img/creative.svg";
import web from "../img/web.svg";
import web_comp from "../img/web_comp.svg";
import content from "../img/content.svg";
import video from "../img/video.svg";
import react from "../img/react.svg";
import seo from "../img/seo.svg";
import text from "../img/text.svg";
import music from "../img/music.svg";
import classify from "../img/classify.svg";
import schedule from "../img/schedule.svg";
import cislogo from "../cis-logo.svg";
import stavLogo from "../vstavlogo.png";
import leuklogo from "../leuklogo.png";
import unilogo from "../unilogo.svg";

import { BulletPoint } from "../CMS/text/BulletPoint";
import { ContentSection } from "../CMS/containers/ContentSection";
import { Hero } from "../Hero";
import { NavBar } from "../NavBar";
import { ProductScroll } from "../ProductScroll";
import { Introduction } from "../Introduction";
import * as Scroll from "react-scroll";
import { Element, scroller } from "react-scroll";
import { ContentDisplay } from "../CMS/show_content/ContentDisplay";
import { BeautyButton } from "../CMS/buttons/BeautyButton";
import { Link } from "react-router-dom";
import { Chip, Chips } from "@mantine/core";
import { PostCard } from "../CMS/cards/PostCard";
import { WideCard } from "../CMS/cards/WideCard";

export const Home = (props) => {
  const [chip, setChip] = useState("");

  //Scroll to section
  useEffect(() => {
    const section = props.match.params.section;
    if (section) {
      scroller.scrollTo(section, {
        delay: 400,
        duration: 1000,
        smooth: true,
      });
      console.log(section);
    }
  });

  return (
    <div className="bg-slate-50">
      <Element id="home">
        <Hero></Hero>
        <NavBar></NavBar>
      </Element>

      <div className="p-20  flex justify-center">
        <div className="flex flex-col md:flex-row mx-auto -mt-56 gap-8 justify-center absolute z-50">
          <button className="bg-white rounded-lg shadow-md p-2 hover:shadow-sm">
            <Link to="/home/web" duration={500}>
              <div className="flex gap-2 group cursor-pointer items-center px-4 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  className="group-hover:translate-y-1 group-hover:text-blue-500 transition-all duration-200"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                </svg>
                <h3 className="font-bold">Websites</h3>
              </div>
            </Link>
          </button>

          <button className="bg-white rounded-lg shadow-md p-2 hover:shadow-sm">
            <Link to="/home/ai" duration={500}>
              <div className="flex group cursor-pointer  gap-2 items-center px-4 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  className="group-hover:rotate-90 group-hover:translate-y-1 group-hover:text-green-500 transition-all duration-200"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                </svg>
                <h3 className="font-bold ">Artificial Intelligence</h3>
              </div>
            </Link>
          </button>
          <button className="bg-white rounded-lg shadow-md p-2 hover:shadow-sm">
            <Link to="/home/content" duration={500}>
              <div className="flex gap-2 group cursor-pointer items-center px-4 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  className="group-hover:translate-y-1 group-hover:text-red-500 transition-all duration-200"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                </svg>
                <h3 className="font-bold">Content</h3>
              </div>{" "}
            </Link>
          </button>
        </div>
      </div>

      <div className="-mt-24">
        {/* <Introduction
          db={props.db}
          title="Portfolio"
          text="Generating the most leads for the smallest investment, optimizing what content engages users most or predicting future patterns your goal is our portfolio."
          content={
            <ContentDisplay
              db={props.db}
              category="Portfolio"
              metadata={props.metadata}
            ></ContentDisplay>
          }
        ></Introduction> */}
        <Element id="what">
          <Introduction
            title="Wat we maken"
            text="Wij excelleren in het bouwen van op maat gemaakte oplossingen voor uw zakelijke processen en aanwezigheid. We creëren websites die hoog scoren in zoekmachines met aangepaste componenten, leveren prachtige inhoud die uw merk versterkt, en bieden unieke AI-oplossingen afgestemd op uw zakelijke behoeften."
          ></Introduction>

          {/* <ProductScroll title="Popular Products"></ProductScroll> */}
        </Element>
        <Element id="portfolio">
          <Introduction
            title="Selectie"
            // text="From delivering AI solutions to ."
          ></Introduction>
          <div className="flex flex-col flex-wrap max-w-5xl md:flex-row justify-center items-center gap-16 mx-auto ">
            <Link to="/posts/PotAIto:%20Potato%20Price%20Forecast">
              {" "}
              <div className="flex flex-col items-center text-center ">
                {" "}
                <img
                  className="w-24 mt-4"
                  src="https://misset.com/wp-content/uploads/2019/01/MissetLogo01FC.png"
                ></img>
                <p className="font-bold w-48 text-xl text-transparent bg-clip-text bg-gradient-to-br from-green-600 to-yellow-500">
                  Aardappel Prijs Forecasts
                </p>
                <p className="-mt-1 text-sm text-gray-400">
                  Samenwerking met Misset
                </p>
              </div>
            </Link>
            <a href="https://www.leukdichtbij.nl">
              {" "}
              <div className="flex flex-col items-center text-center ">
                {" "}
                <img className="w-24 h-24" src={leuklogo}></img>
                <p className="font-bold w-48 text-xl text-transparent bg-clip-text bg-gradient-to-br from-green-600 to-yellow-500">
                  LEUKDICHTBIJ.NL
                </p>
                <p className="-mt-1 text-sm text-gray-400">
                  De grootste database uitjes van NL
                </p>
              </div>
            </a>
            <a href="https://www.unimixbrasil.com.br/">
              <div className="flex flex-col items-center text-center ">
                <img className="w-24 h-24" src={unilogo}></img>{" "}
                <p className="font-bold w-32 text-2xl text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-yellow-500">
                  UNIMIX
                </p>
                <p className="-mt-1 text-gray-400">OILS</p>
              </div>
            </a>
            <a href="https://www.hetcis.nl">
              {" "}
              <div className="flex flex-col items-center text-center ">
                {" "}
                <img className="w-52" src={cislogo}></img>
                <p className="text-sm -mt-6 text-gray-400">HET</p>{" "}
                <p className="font-bold w-32 text-3xl text-transparent text-sky-400">
                  CIS
                </p>
              </div>
            </a>
            <a href="https://www.vanstaverenbegrafenisverzorging.nl/">
              <div className="flex flex-col items-center text-center ">
                {" "}
                <img className="w-32 h-32" src={stavLogo}></img>
                <p className="font-bold w-48 text-xl text-transparent text-gray-500">
                  VAN STAVEREN
                </p>
              </div>
            </a>
          </div>
        </Element>
        <div className="mt-16" />
        <Element id="ai">
          <ContentSection
            title="Kunstmatige Intelligentie"
            content={
              <>
                {" "}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <BulletPoint
                    title="Voorspel toekomstige patronen"
                    text="Voorspel de verkopen van volgende week, de bewegende prijs van een product, het aantal afzeggingen voor afspraken en elk patroon dat in de loop van de tijd verandert."
                    img={report}
                  ></BulletPoint>
                  <BulletPoint
                    title="Plan efficiënt"
                    text="Plan nauwkeurig en efficiënt met beperkingen op tijd, locatie en afhankelijkheden."
                    img={schedule}
                  ></BulletPoint>
                  <BulletPoint
                    title="Classificeer correct"
                    text="Van geschreven documentherkenning tot gezichtsherkenning, tot het classificeren van de gemiddelde positiviteit van beoordelingen per week; alles wat een mens in principe classificeert, kan door AI worden geclassificeerd."
                    img={classify}
                  ></BulletPoint>
                  <BulletPoint
                    title="Transformeer"
                    text="Transformeer grote tekstcollecties naar sets van meest voorkomende trefwoorden, gesproken audio naar tekst, een beschrijving van een foto naar een foto, enz."
                    img={creative}
                  ></BulletPoint>
                </div>
              </>
            }
          ></ContentSection>
        </Element>
        <Element id="web">
          <ContentSection
            title="Webontwikkeling"
            content={
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <BulletPoint
                    title="Ontwerp aangepaste websites"
                    text="Krijg een modern, snel en fris aangepast ontwerp precies naar uw wens."
                    img={web}
                  ></BulletPoint>
                  <BulletPoint
                    title="Integreer elke functionaliteit"
                    text="Integreer een contentbeheersysteem, sociale media, webshop, chatbots of gebruikersfeeds op uw website."
                    img={web_comp}
                  ></BulletPoint>
                  <BulletPoint
                    title="Modern en snel"
                    text="Gebouwd op de meest moderne en snelle technologieën met toekomstige uitbreidbaarheid in gedachten."
                    img={react}
                  ></BulletPoint>
                  <BulletPoint
                    title="Gebruikers- en SEO-vriendelijk"
                    text="Zorg ervoor dat zoekmachines uw site prefereren en gebruikers uw site leuk vinden."
                    img={seo}
                  ></BulletPoint>
                </div>
              </>
            }
          ></ContentSection>
        </Element>
        <Element id="content">
          <ContentSection
            title="Content Marketing"
            content={
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <BulletPoint
                    title="Mooie video-inhoud"
                    text="Ontvang visueel boeiende inhoud gemaakt door professionele filmmakers en contentmakers die prachtig en helder digitaal (video-)materiaal creëren."
                    img={video}
                  ></BulletPoint>
                  <BulletPoint
                    title="Conversies verkrijgen door artikelen"
                    text="Een team van bekwame content-marketeers maakt efficiënt content die gebruikers naar uw website leidt."
                    img={text}
                  ></BulletPoint>
                  <BulletPoint
                    title="SEO geoptimaliseerd"
                    text="We gebruiken ingebouwde systemen voor Google ranking optimalisatie, zoals artikel lengtes, sterke trefwoorden van vergelijkbare hoog scorende posts en afbeeldingsplaatsingen voor optimale Google ranking."
                    img={music}
                  ></BulletPoint>
                  <BulletPoint
                    title="Optimaliseer marketing"
                    text="Bouw moderne, geteste marketingcampagnes met een team van creatievelingen die gebruik maken van moderne geoptimaliseerde algoritmes en geautomatiseerde processen voor boeiende inhoud."
                    img={content}
                  ></BulletPoint>
                </div>
              </>
            }
          ></ContentSection>
        </Element>
      </div>
      <div className="w-full h-32 bg-slate-800 flex flex-col justify-center items-center gap-yarn4">
        <p className="text-xl font-bold text-white">GARZEN</p>
        {/* <Link
          to="/dashboard"
          className="text-green-400 font-bold cursor-pointer border-b border-green-400 hover:border-green-200 hover:text-green-100 transition-all"
        >
          Dashboard
        </Link> */}
      </div>
    </div>
  );
};
