import React from "react";

export const Introduction = (props) => {
  return (
    <div
      className={`mx-auto max-w-3xl p-4 text-center mt-10 mb-20 ${
        props.noMarginTop && "mt-0"
      }`}
    >
      <h2 className="mx-auto font-base text-6xl ">{props.title}</h2>
      <p className="text-gray-700 mt-10">{props.text}</p>
      {props.content}
      {props.line && <div className="w-full h-0 border-t mt-10"></div>}
    </div>
  );
};
